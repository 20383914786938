/* eslint-disable no-sequences */
import { useContext, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { UpOutlined } from '@ant-design/icons';
import Headroom from 'react-headroom';
import dynamic from 'next/dynamic';

import { getIpadCountries } from 'actions/products';

import { CloseMdSVG } from 'components/iconsSVG/close';
import { ChevronSVG } from 'components/iconsSVG/chevron';
import { Modal, Select } from 'antd';
import { HeadSEO } from 'components/base';
import { MyAccountModal } from 'components/myAccount';
import Breadcrumb from 'components/breadcrumb';
import I18nModal from 'components/i18nModal';
import { NewsletterModal } from 'components/newsletterModal';
import EventsTwsModal from 'components/eventsTwsModal';
import GiftBagModal from 'components/giftBagModal';
import { MyOrderModal, GlobalEOrdersWidget } from 'components/myOrderModal';
import { i18nContext } from 'context/i18n';
import { userContext } from 'context/user';

import { IS_CAMPER, IS_NNORMAL, GLOBAL_E_SCRIPTS_ID } from 'utils/constants/system';
import { TWS_MAGAZINES, TWS_MAGAZINE_LANGS } from 'utils/constants/tws';

import {
  getLang,
  getMarket,
  getCookie,
  eraseCookie,
  getIsMobile,
  COOKIES,
  setCookie,
  getCountryLanguages,
  changeLocaleInUrl,
  getUnbxdTranslations,
} from 'utils/helpers';
import { dataLayerHandleEvent } from 'utils/dataLayers';

import AbandonedCartBanner from 'components/abandonedCartBanner';
import SearchTNM from 'components/search';

import styles from './style.module.css';

const Footer =
  IS_CAMPER ?
    dynamic(() => import('@camper/react-web-components').then((res) => res.ORGANISMS.Footer))
  : dynamic(() => import('@nnormal/react-web-components').then((res) => res.ORGANISMS.Footer));
const TnmComponent =
  IS_CAMPER ?
    dynamic(() => import('@camper/react-web-components').then((res) => res.ORGANISMS.TnmComponent))
  : dynamic(() => import('@nnormal/react-web-components').then((res) => res.ORGANISMS.TnmComponent));
const TnmNewComponent = IS_CAMPER ? dynamic(() => import('@camper/react-web-components').then((res) => res.ORGANISMS.TnmNewComponent)) : null;
const Ribbon = dynamic(() => import('components/ribbon'));
const MiniBag = dynamic(() => import('components/miniBag').then((mod) => mod.MiniBag));
const MyAccountWidget = dynamic(() => import('components/myAccount'));
const MembersOnlyModal = dynamic(() => import('components/productPurchaseCol/membersOnlyModal'));

const widgetBagHidden = ['/compra'];

const myOrderEvents = {
  account: {
    event: 'main_menu',
    event_category: 'menu',
    event_detail_1: 'my account',
    event_detail_2: 'orders',
  },
  tracking: {
    event: 'footer_menu',
    event_category: 'menu',
    event_detail_1: 'shopping on website',
    event_detail_2: 'order tracking',
  },
};

function showNewTnmMobile(market) {
  if (!market) return false;
  return [
    'AT',
    'PL',
    'CH',
    'IE',
    'NL',
    'XI',
    'DK',
    'FI',
    'NO',
    'SE',
    'CA',
    'HK',
    'ES',
    'PT',
    'FR',
    'MC',
    'BE',
    'IT',
    'GR',
    'SG',
    'AD',
    'CY',
    'CZ',
    'EE',
    'LT',
    'LU',
    'MT',
    'LV',
    'RO',
    'SI',
    'SK',
    'IL',
    'AL',
    'BR',
    'OM',
    'EC',
    'ID',
    'VN',
    'MA',
    'SA',
    'PH',
    'MY',
    'EG',
    'CR',
    'DO',
    'KW',
    'MD',
    'GT',
    'VE',
    'JO',
    'NG',
    'PA',
    'BD',
    'KE',
    'BO',
    'SV',
    'SN',
    'KH',
    'LK',
    'JM',
    'BH',
    'HN',
    'TZ',
    'PY',
    'ET',
    'BS',
    'KY',
    'BM',
    'NI',
    'NA',
    'BW',
    'AR',
    'LB',
    'AE',
    'BA',
    'IN',
    'IS',
    'LI',
    'ZA',
    'PG',
    'BN',
    'HU',
    'BG',
    'HR',
    'QA',
    'DZ',
    'US',
    'DE',
    'GB',
    'PE',
    'TH',
    'TW',
    'TR',
    'KR',
    'AU',
    'CL',
    'JP',
    'MX',
  ].includes(market);
}

// meter unbxd keys de t y config unbxd para usesNewSearch searchBoxJSON
const SearchBoxJson = (lang, country) => {
  const searchAction = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: `https://www.camper.com/${lang}_${country}`,
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: `https://www.camper.com/${lang}_${country}/search?searchTerm={search_term_string}`,
      },
      'query-input': 'required name=search_term_string',
    },
  };
  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(searchAction) }} />;
};

export default function Layout({
  children,
  footerProps,
  withFooter = true,
  withHead = true,
  tnmProps,
  breadcrumbs = true,
  breadcrumbsBottom = false,
  breadcrumbI18n = {},
  breadcrumbFragments = [],
  ribbon = [],
  metatags = null,
  extratags = [],
  camperlab = false,
  scrollArrow = false,
  idBag = null,
  pageName = null,
  translateSlug = false,
  recycled = false,
  gtmProps,
  popupProps,
}) {
  const { pathname } = useRouter();
  const isMobile = getIsMobile('768px');
  const { locale, countries, t, profileData, appmode, popupInfo, storeData, i18nPage, setStoreData } = useContext(i18nContext);

  const { global_e, currency } = profileData;
  const { setAppliedVoucher } = useContext(userContext);
  const [showingI18nModal, setShowingI18nModal] = useState(false);
  const [showingNewsletterModal, setShowingNewsletterModal] = useState(false);
  const [showingMyAccountModal, setShowingMyAccountModal] = useState(false);
  const [showingMyOrderModal, setShowingMyOrderModal] = useState(false);
  const [showingEventsTwsModal, setShowingEventsTwsModal] = useState(false);
  const [eventsTwsModalType, setEventsTwsModalType] = useState('subscribe');
  const [eventTwsEmail, setEventTwsEmail] = useState('');
  const [showingMembersOnlyModal, setShowingMembersOnlyModal] = useState(false);
  const [showingTnm, setShowingTnm] = useState(false);
  const [nlOrigin, setNLOrigin] = useState('');
  const [membersOnlyType, setMembersOnlyType] = useState('ewallet');
  const [membersOnlyHref, setMembersOnlyHref] = useState('');
  const [scrollArrowVisible, setScrollArrowVisible] = useState(false);
  const [showingRibbon, setShowingRibbon] = useState(true);
  const [userCS, setUserCS] = useState(null);
  const [visible, setVisible] = useState(false);
  const [urlBagRedirect, setUrlBagRedirect] = useState(null);
  const [showGiftBag, setShowGiftBag] = useState(false);
  const [storeCode, setStoreCode] = useState(null);

  const [showFooter, setShowFooter] = useState(withFooter);
  const [showHeader, setShowHeader] = useState(withHead);
  const [modalSource, setModalSource] = useState(null);
  const [modalSendUsrHash, setModalSendUsrHash] = useState(null);

  const accountWidget = useRef(null);
  const currentBond = useRef(null);

  const market = getMarket(locale);

  const unbxdTranslations = useMemo(() => getUnbxdTranslations(t), [t]);
  const usesNewSearch = unbxdTranslations !== false && profileData?.config?.unbxd === true;

  const handleOpenNewsletterModal = (origin) => {
    // para cualquier check que tengamos que hacer
    // e.g. estoy logedo y ya estoy suscrito === no lo mostramos
    currentBond.current = getCookie(COOKIES.BONDS);
    setNLOrigin(origin);
    setShowingNewsletterModal(true);
  };

  const handleChangeOrdersVisibility = (value, origin) => {
    setShowingMyOrderModal(value);

    if (origin) {
      const event = myOrderEvents[origin];

      if (event) {
        dataLayerHandleEvent(event);
      }
    }
  };

  const handleCloseNewsletterModal = () => {
    setShowingNewsletterModal(false);
    const currentBondValue = currentBond.current;
    const bondCookie = getCookie(COOKIES.BONDS);
    if (bondCookie && bondCookie !== currentBondValue) {
      setAppliedVoucher(true);
    }
  };

  const onClickSignIn = () => {
    setShowingMyAccountModal(true);
  };

  useEffect(() => {
    if (storeData && storeData?.centro) {
      setStoreCode(storeData.centro);
    }
  }, [storeData]);

  useEffect(() => {
    const handleLoadIpadCountries = async () => {
      const cookieStoreData = JSON.parse(decodeURIComponent(getCookie(COOKIES.STORE_DATA)));

      if (cookieStoreData) {
        cookieStoreData.countries = await getIpadCountries({ profile: locale });

        setStoreData(cookieStoreData);
      }
    };

    if (window !== undefined) {
      setUserCS(getCookie(COOKIES.CS_EMPLOYEE_ID));
      handleLoadIpadCountries();

      window.sendDataLayer = (eventPayload) => {
        dataLayerHandleEvent(eventPayload);
      };

      window.showGiftBagPopUp = () => {
        setShowGiftBag(true);
      };

      window.showEventsTwsModal = (id) => {
        setShowingEventsTwsModal(id);
      };
      window.showOrderPopUp = () => {
        setShowingMyOrderModal(true);
      };

      window.showMembersOnlyModal = (origin, popupType = '', href = '') => {
        let open = true;
        if (popupType.includes('magazine')) {
          const usrHash = getCookie(COOKIES.SELLIGENT_USRHASH);
          if (usrHash && usrHash !== '') {
            open = false;
            const lang = getLang(locale);
            const magazineType = popupType.split('-').pop();
            if (href && href?.length > 0) {
              window.location.assign(href);
            } else {
              window.open(
                TWS_MAGAZINES[magazineType in TWS_MAGAZINES ? magazineType : 'menorca'].replace('LANG', TWS_MAGAZINE_LANGS.includes(lang) ? lang : 'en'),
                '_blank',
              );
            }
          }
        }
        if (open) {
          setShowingMembersOnlyModal(true);
          setNLOrigin(origin || popupType);
          setMembersOnlyType(popupType);
          setMembersOnlyHref(href || '');
        }
      };

      window.onscroll = () => {
        const { y } = window.document.body.getBoundingClientRect();

        setScrollArrowVisible(y < -400);
        setShowingRibbon(y === 0);
      };

      const urlParams = new URLSearchParams(window.location.search);
      const eventId = urlParams.get('eventId');
      const email = urlParams.get('email');

      if (eventId && email) {
        setShowingEventsTwsModal(eventId);
        setEventsTwsModalType('unsubscribe');
        setEventTwsEmail(email);
      }

      if (eventId) {
        setShowingEventsTwsModal(eventId);
      }

      if (global_e) {
        const [lang, country] = [getLang(locale), getMarket(locale)];
        const data = {
          countryISO: country,
          currencyCode: currency,
          cultureCode: lang,
        };

        const GlobalE_Data = encodeURIComponent(JSON.stringify(data));

        setCookie(COOKIES.GLOBAL_E, GlobalE_Data, 1, COOKIES.DOMAIN);
      }
    }
  }, []);

  // separamos el useEffect del open newsletterModal
  const handleWindowOpenNewsletterModal = useCallback(
    (source, sendUsrHash) => {
      if (showingNewsletterModal) return;
      setModalSource(source);
      setModalSendUsrHash(sendUsrHash);
      handleOpenNewsletterModal(source || 'popup');
    },
    [showingNewsletterModal],
  );

  useEffect(() => {
    window.openNewsletterModal = handleWindowOpenNewsletterModal;
  }, [handleWindowOpenNewsletterModal]);

  useEffect(() => {
    // para los países de globale accountWidget tiene que ser diferente solo en base a orders
    accountWidget.current = <MyAccountWidget onClickYourOrders={() => handleChangeOrdersVisibility(true, 'account')} onClickSignIn={onClickSignIn} />;

    if (global_e) {
      accountWidget.current =
        isMobile ? null : (
          <GlobalEOrdersWidget onOpen={() => handleChangeOrdersVisibility(true, 'account')} onClose={() => handleChangeOrdersVisibility(false)} />
        );
    }
  }, [global_e, isMobile]);

  const closeSession = () => {
    eraseCookie(COOKIES.CS_EMPLOYEE_ID);
    eraseCookie(COOKIES.STORE_DATA);
    setUserCS(null);
    setStoreCode(null);
    window.location.assign(`/${locale}/store_ipad`);
  };

  const generatedURLBag = (id) => {
    setVisible(true);
    const url = `${window.location.origin}/${locale}/bolsa?hash=${id}`;
    setUrlBagRedirect(url);
  };

  const handleOnPinNav = () => {
    const filterNav = document.querySelector('#plp-filter-nav');
    const isDesktop = !(window.innerWidth < 1200);
    const tnmSelector = `.tnm--${isDesktop ? 'desktop' : 'mobile'}`;
    if (filterNav) {
      const tnmHeight = document.querySelector(tnmSelector).offsetHeight;
      filterNav.style.top = `${tnmHeight}px`;
      const filterPanel = document.querySelector('#plp-filter-panel');
      filterPanel.firstChild.style.top = `${filterNav.offsetHeight + tnmHeight}px`;
      filterPanel.firstChild.style.maxHeight = `calc(100vh - ${filterNav.offsetHeight + tnmHeight}px)`;
    }
    const stickyCol = document.querySelector('#sticky-col');
    if (stickyCol) {
      const tnmHeight = document.querySelector(tnmSelector).offsetHeight;
      stickyCol.style.top = `${tnmHeight}px`;
    }
    const landingSticky = document.querySelector('#landing-sticky');
    if (landingSticky) {
      const tnmHeight = document.querySelector(tnmSelector).offsetHeight;
      landingSticky.style.top = `${tnmHeight}px`;
    }
  };
  const handleOnUnpinNav = () => {
    const filterNav = document.querySelector('#plp-filter-nav');
    if (filterNav) {
      filterNav.style.top = '0px';
      const filterPanel = document.querySelector('#plp-filter-panel');
      filterPanel.firstChild.style.top = `${filterNav.offsetHeight}px`;
      filterPanel.firstChild.style.maxHeight = `calc(100vh - ${filterNav.offsetHeight}px)`;
    }
    const stickyCol = document.querySelector('#sticky-col');
    if (stickyCol) {
      stickyCol.style.top = '0px';
    }
    const landingSticky = document.querySelector('#landing-sticky');
    if (landingSticky) {
      landingSticky.style.top = '0px';
    }
  };
  const onTnmShow = () => {
    if (window?.hideAutosuggest) {
      window.hideAutosuggest();
    }

    if (!showingTnm) {
      setShowingTnm(true);
    }
  };

  const onTnmHide = () => {
    setShowingTnm(false);
  };

  const scripts = [];

  if (global_e) {
    scripts.push(
      <script
        key="global-e-external"
        type="text/javascript"
        id="GlobalEScript"
        src={
          process.env.ENVIRONMENT === 'prod' ?
            `https://gepi.global-e.com/includes/js/${GLOBAL_E_SCRIPTS_ID}`
          : `https://intgepi.bglobale.com/includes/js/${GLOBAL_E_SCRIPTS_ID}`
        }
        defer
      />,
      <link
        id="GEPIStyles"
        key="global-e-css"
        rel="stylesheet"
        href={
          process.env.ENVIRONMENT === 'prod' ?
            `https://gepi.global-e.com/includes/css/${GLOBAL_E_SCRIPTS_ID}?countryCode=${getMarket(locale)}`
          : `https://intgepi.bglobale.com/includes/css/${GLOBAL_E_SCRIPTS_ID}?countryCode=${getMarket(locale)}`
        }
      />,
    );
  }

  if (usesNewSearch) {
    scripts.push(SearchBoxJson());
  }

  let ordersWidget = <MyOrderModal locale={locale} visible={showingMyOrderModal} onClose={() => setShowingMyOrderModal(false)} />;

  if (global_e) {
    ordersWidget = (
      <GlobalEOrdersWidget
        onOpen={() => setShowingMyOrderModal(true)}
        visible={showingMyOrderModal}
        onlyModal={true}
        onClose={() => setShowingMyOrderModal(false)}
      />
    );
  }

  const extraTags = [...extratags];

  let layoutStyle = undefined;
  if (recycled) {
    layoutStyle = { '--background': 'var(--color-recycled)' };
  } else if (camperlab) {
    layoutStyle = { '--background': 'var(--color-camperlab)', '--font-primary': 'var(--font-camperlab)' };
  }

  return (
    <div className={styles.layout} data-camperlab={camperlab ? 'true' : undefined} style={layoutStyle}>
      {(storeCode || userCS) && (
        <div className={styles.userCScontainer}>
          <img className={styles.infoCVC} src="/assets-new/account-trigger.svg" alt="Info" width={20} height={20} />
          <span className={styles.userLine}> {storeCode || userCS}</span>
          {idBag && (
            <a className={styles.userCSLink} onClick={() => generatedURLBag(idBag)}>
              {' '}
              generar URL
            </a>
          )}

          <Modal
            visible={visible}
            title={t('bolsa', 'url.customer.service', 'URL generada')}
            destroyOnClose={true}
            onCancel={() => setVisible(false)}
            closeIcon={<CloseMdSVG />}
            maskClosable={false}
            footer={null}
            closable={true}
          >
            <p>{urlBagRedirect}</p>
          </Modal>

          <a className={styles.userCSLink} onClick={() => closeSession()}>
            {' '}
            Close session
          </a>

          {i18nPage !== 'Bag' && (
            <div className={styles.countryToSendSelect}>
              {storeData && storeData?.countries ?
                <Select
                  suffixIcon={<ChevronSVG />}
                  placeholder={t('modal.idiomas', 'select.country', 'Select a country')}
                  filterOption={false}
                  onChange={(val) => {
                    const searchURL = new URLSearchParams(window.location.search);
                    const langToSend = getCountryLanguages(storeData.countries, val);
                    const countryPriceCookie = getCookie(COOKIES.COUNTRY_PRICE);
                    const countryPriceURL = searchURL.get('country_price');

                    if (countryPriceURL === null) {
                      // no tiene por url, comprueba valores cookie
                      if (countryPriceCookie !== null) {
                        // tiene por cookie, mete el valor en url y no toques nada
                        searchURL.set('country_price', countryPriceCookie);
                      } else {
                        // setea la cookie first time con market
                        searchURL.set('country_price', market);
                        setCookie(COOKIES.COUNTRY_PRICE, market, 1, COOKIES.DOMAIN);
                      }
                    } else {
                      // tiene por url, pisa cookie siempre
                      setCookie(COOKIES.COUNTRY_PRICE, countryPriceURL, 1, COOKIES.DOMAIN);
                    }

                    searchURL.delete('country');
                    searchURL.delete('lng');

                    changeLocaleInUrl(`${langToSend}_${val}`);
                  }}
                  value={storeData?.countryToSend || market}
                  className={`${styles.modalSelect} ${styles.noFloatLabel}`}
                  virtual={false}
                >
                  {storeData?.countries?.map((country) => (
                    <Select.Option key={country.id} className={styles.selectItem}>
                      <p>{country.name}</p>
                    </Select.Option>
                  ))}
                </Select>
              : <Select
                  suffixIcon={<ChevronSVG />}
                  placeholder={t('modal.idiomas', 'select.country', 'Select a country')}
                  className={`${styles.modalSelect} ${styles.noFloatLabel}`}
                  virtual={false}
                />
              }
            </div>
          )}
        </div>
      )}

      <HeadSEO
        title={metatags?.pageTitle}
        description={metatags?.metaDescription}
        scripts={scripts}
        pageName={pageName}
        extraTags={extraTags}
        gtmProps={gtmProps}
      />
      {ribbon && ribbon.length > 0 && showHeader && (
        <Ribbon
          show={showingRibbon}
          items={ribbon}
          onClickNewsletter={() => {
            setModalSource('header');
            handleOpenNewsletterModal('header');
            // setNLOrigin('header'); setShowingNewsletterModal(true);
          }}
        />
      )}
      {showHeader && (
        <header className={styles.headerNav}>
          {tnmProps && (
            <Headroom onPin={handleOnPinNav} onUnpin={handleOnUnpinNav}>
              {(
                IS_CAMPER && showNewTnmMobile(market) // que si no TnmNewComponent es null
              ) ?
                <TnmNewComponent
                  bagWidget={!widgetBagHidden.includes(pathname) ? <MiniBag locale={locale} isInSalePath={pathname === '/bolsa'} /> : null}
                  onTnmShow={onTnmShow}
                  onTnmHide={onTnmHide}
                  showSearch={!IS_NNORMAL}
                  searcher={<SearchTNM />}
                  {...tnmProps}
                  accountWidget={accountWidget.current}
                  showI18nModal={() => setShowingI18nModal(true)}
                  showNewsletterModal={() => {
                    handleOpenNewsletterModal('footer');
                    setNLOrigin('footer');
                    setShowingNewsletterModal(true);
                  }}
                  showOrderTrackingModal={() => setShowingMyOrderModal(true)}
                />
              : <TnmComponent
                  bagWidget={!widgetBagHidden.includes(pathname) ? <MiniBag locale={locale} isInSalePath={pathname === '/bolsa'} /> : null}
                  onTnmShow={onTnmShow}
                  onTnmHide={onTnmHide}
                  showSearch={!IS_NNORMAL}
                  searcher={<SearchTNM />}
                  {...tnmProps}
                  accountWidget={accountWidget.current}
                  showI18nModal={() => setShowingI18nModal(true)}
                  showNewsletterModal={() => {
                    handleOpenNewsletterModal('footer');
                    setNLOrigin('footer');
                    setShowingNewsletterModal(true);
                  }}
                  showOrderTrackingModal={() => setShowingMyOrderModal(true)}
                />
              }
              <AbandonedCartBanner />
            </Headroom>
          )}
        </header>
      )}
      <div id="layoutContent" className={showingTnm ? `${styles.backdrop} ${styles.backdropVisible}` : styles.backdrop}>
        {breadcrumbs && !breadcrumbsBottom && <Breadcrumb locale={locale} i18nKeys={breadcrumbI18n} camperlab={camperlab} fragments={breadcrumbFragments} />}
        {children}
        {breadcrumbs && breadcrumbsBottom && (
          <div className={styles.breadcrumbsBottom}>
            <Breadcrumb locale={locale} i18nKeys={breadcrumbI18n} camperlab={camperlab} fragments={breadcrumbFragments} pageName={pageName} />
          </div>
        )}
        {showFooter ?
          <>
            {footerProps ?
              <Footer
                {...footerProps}
                showI18nModal={() => setShowingI18nModal(true)}
                showNewsletterModal={() => {
                  setModalSource('footer');
                  handleOpenNewsletterModal('footer');
                  // setNLOrigin('footer'); setShowingNewsletterModal(true);
                }}
                profileData={profileData}
                showOrderTrackingModal={() => {
                  handleChangeOrdersVisibility(true, 'tracking');
                }}
                newsletterTitle={popupInfo?.title || t('header.messages', `header.ribbon.01.${getMarket(locale)}.title`)}
                newsletterDescription={popupInfo?.text || t('header.messages', `header.ribbon.01.${getMarket(locale)}.text`)}
              />
            : null}
            <I18nModal countries={countries} visible={showingI18nModal} changeVisibility={setShowingI18nModal} translateSlug={translateSlug} />
            <NewsletterModal
              countries={countries}
              locale={locale}
              visible={showingNewsletterModal}
              onClose={handleCloseNewsletterModal}
              nlOrigin={nlOrigin}
              pageName={pageName}
              source={modalSource}
              sendUsrHash={modalSendUsrHash}
              popupProps={popupProps}
              camperlab={camperlab}
              cmsProps={
                footerProps ?
                  {
                    newsletterPopup: footerProps.newsletterPopup,
                    newsletterContent: footerProps.newsletterContent,
                    newsletterPopupImages: footerProps.newsletterPopupImages,
                  }
                : undefined
              }
            />
            <EventsTwsModal
              loading={false}
              id={showingEventsTwsModal === false ? '0' : showingEventsTwsModal}
              modalTwsVisible={showingEventsTwsModal !== false}
              setModalTwsVisible={setShowingEventsTwsModal}
              onFinishOk={() => console.log('ok')}
              type={eventsTwsModalType}
              email={eventTwsEmail}
            />
            <MembersOnlyModal
              loading={false}
              membersOnlyVisible={showingMembersOnlyModal}
              setModalMembersOnlyVisible={setShowingMembersOnlyModal}
              nlOrigin={nlOrigin}
              type={membersOnlyType}
              onFinishOk={() => console.log('ok')}
              href={membersOnlyHref}
            />
          </>
        : null}
        {scrollArrow && (
          <button
            className={`${styles.scrollArrow} ${scrollArrowVisible ? '' : 'invisible'} data-[visible=desktop]:max-md:hidden data-[visible=mobile]:md:hidden`}
            data-visible={typeof scrollArrow === 'string' ? scrollArrow : undefined}
            onClick={() => {
              if (window && window.document) {
                const [body] = document.getElementsByTagName('body');

                if (body) {
                  body.scrollIntoView({ behavior: 'smooth' });
                }
              }
            }}
          >
            <UpOutlined />
          </button>
        )}
        <MyAccountModal locale={locale} visible={showingMyAccountModal} onClose={() => setShowingMyAccountModal(false)} />
        {ordersWidget}
      </div>
      <GiftBagModal visible={showGiftBag} onClose={() => setShowGiftBag(false)} />
    </div>
  );
}
